import * as React from 'react';
import * as styles from './contact.module.scss';

import mapboxgl from 'mapbox-gl';

import Footer from '../../Footer';

export default ({ mobile }: { mobile: boolean }) => {
  mapboxgl.accessToken =
    'pk.eyJ1IjoicHJpbWVyYS1jYXBpdGFsLW1hcGJveCIsImEiOiJjano0MDN0aXEwOGMxM25vOHduZmliN2FqIn0.ndPTvDjezFFOvnO0rvVTDg';
  const mapRef = React.useCallback(node => {
    if (node !== null) {
      const map = new mapboxgl.Map({
        container: node,
        style:
          'mapbox://styles/primera-capital-mapbox/cjz41471p17141cpdaoxyocef',
        center: [-122.275, 37.804],
        zoom: 11.5,
      });
    }
  }, []);
  return (
    <div className={styles.container}>
      {!mobile ? <div ref={mapRef} className={styles.mapContainer} /> : null}
      <div className={styles.contactContainer}>
        <div className={styles.contentContainer}>
          <h2 className={styles.heading}>Contact</h2>
          <p className={styles.phone}>+1-408-475-4562</p>
          <p className={styles.email}>plans@primeracapital.com</p>
          <p className={styles.location}>
            555 12th Street, Suite 910 Oakland, CA 94607
          </p>
        </div>
        {mobile ? <div ref={mapRef} className={styles.mapContainer} /> : null}
        <Footer light={true} />
      </div>
    </div>
  );
};
