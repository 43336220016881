import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import BlankPage from '../templates/BlankPage';
import Contact from '../components/contact/Contact';
import Header from '../components/Header';

interface Props {}

interface State {
  width: number;
}

export default class ContactPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      width: 1000,
    };
  }

  shouldComponentUpdate(_: any, nextState: State) {
    // Only rerender if a breakpoint has changed and the carousel is to be stopped.
    return (
      (this.state.width < 480 && nextState.width >= 480) ||
      (this.state.width >= 480 && nextState.width < 480)
    );
  }

  componentDidMount() {
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }

  updateWidth = () => this.setState({ width: window.innerWidth });

  render() {
    return (
      <Layout>
        <SEO pageTitle="Contact" description="" />
        <Header
          background={this.state.width < 480}
          alternateMenuTheme={this.state.width >= 480}
          light={this.state.width < 480}
        />
        <BlankPage>
          <Contact mobile={this.state.width < 480} />
        </BlankPage>
      </Layout>
    );
  }
}
